import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { app } from '../firebase'; // Make sure this import is correct
import { getFirestore, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { updateExpirationTimes } from './firestoreService';
import { serverTimestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const auth = getAuth(app);
const firestore = getFirestore(app);
const db = firestore; // Add this line to define db

const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    console.log('Starting Google sign-in process');
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    console.log('User signed in with Google, UID:', user.uid);

    // Check if the user document already exists
    const userDocRef = doc(firestore, 'users', user.uid);
    const docSnap = await getDoc(userDocRef);

    let isNewUser = false;
    if (!docSnap.exists()) {
      // Create a user document in Firestore if it doesn't exist
      await setDoc(userDocRef, {
        email: user.email,
        isSubscribed: false,
        createdAt: new Date().toISOString(),
        isNewUser: true,
        nickname: 'Guest'
      });
      console.log('User document created in Firestore for Google sign-in');
      isNewUser = true;
    } else {
      console.log('User document already exists in Firestore');
    }

    // Update login streak
    try {
      const functions = getFunctions();
      const updateLoginStreak = httpsCallable(functions, 'updateLoginStreak');
      await updateLoginStreak();
    } catch (streakError) {
      console.error('Error updating login streak:', streakError);
    }

    return { user, isNewUser };
  } catch (error) {
    console.error("Error signing in with Google", error);
    throw error;
  }
};

const signUpWithEmail = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    // Create a user profile document
    await setDoc(doc(db, 'users', user.uid), {
      email: user.email,
      createdAt: serverTimestamp(),
      isNewUser: true,
      isSubscribed: false,
      nickname: 'Guest'
    });
    return user;
  } catch (error) {
    console.error("Error signing up with email", error);
    throw error;
  }
};

const signInWithEmail = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    
    // Update login streak
    try {
      const functions = getFunctions();
      const updateLoginStreak = httpsCallable(functions, 'updateLoginStreak');
      await updateLoginStreak();
    } catch (streakError) {
      console.error('Error updating login streak:', streakError);
    }
    
    return result.user;
  } catch (error) {
    console.error("Error signing in with email", error);
    throw error;
  }
};

const signOutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out", error);
    throw error;
  }
};

const getCurrentUser = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      if (user) {
        try {
          const userDoc = await getDoc(doc(firestore, 'users', user.uid));
          const userData = userDoc.data();
          resolve({
            ...user,
            isSubscribed: userData?.isSubscribed || false
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
          resolve(user);
        }
      } else {
        resolve(null);
      }
    }, reject);
  });
};

const updateUserSubscriptionStatus = async (userId, isSubscribed) => {
  try {
    const userRef = doc(firestore, 'users', userId);
    await updateDoc(userRef, {
      isSubscribed: isSubscribed,
      subscriptionUpdatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating user subscription status:', error);
    throw error;
  }
};

const getUserSubscriptionStatus = async () => {
    const user = auth.currentUser;
    if (!user) return false;

    try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (!userDoc.exists()) {
            return false;
        }
        return userDoc.data().isSubscribed || false;
    } catch (error) {
        console.error('Error fetching user subscription status:', error);
        return false;
    }
};

const cancelUserSubscription = async (userId) => {
  try {
    const userRef = doc(firestore, 'users', userId);
    await updateDoc(userRef, {
      isSubscribed: false,
      subscriptionCancelledAt: new Date().toISOString()
    });
    return true; // Return true if cancellation was successful
  } catch (error) {
    console.error('Error cancelling user subscription:', error);
    return false; // Return false if there was an error
  }
};

export const checkSubscriptionStatus = async (user) => {
  const userDoc = await getDoc(doc(db, 'users', user.uid));
  return userDoc.data()?.isSubscribed || false;
};

export const handleSubscriptionCancellation = async (user) => {
  const userDoc = await getDoc(doc(db, 'users', user.uid));
  const wasSubscribed = userDoc.data()?.isSubscribed || false;
  
  // Only update expiration times if the user was previously subscribed
  if (wasSubscribed) {
    await updateExpirationTimes(user.uid, false);
  }
  
  return false; // Return new subscription status
};

export {
  signInWithGoogle,
  signUpWithEmail,
  signInWithEmail,
  signOutUser,
  getCurrentUser,
  updateUserSubscriptionStatus,
  getUserSubscriptionStatus,
  cancelUserSubscription
};

export const updateUserProfile = async (uid, data) => {
  try {
    const userRef = doc(db, 'users', uid);
    await setDoc(userRef, data, { merge: true });
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  const userRef = doc(firestore, 'users', userId);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    return userSnap.data();
  } else {
    throw new Error('User profile not found');
  }
};

export const refreshUserData = async (userId) => {
  try {
    const userRef = doc(firestore, 'users', userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        ...userData,
        id: userId,
        isSubscribed: userData.isSubscribed || false
      };
    } else {
      throw new Error('User document not found');
    }
  } catch (error) {
    console.error('Error refreshing user data:', error);
    throw error;
  }
};
