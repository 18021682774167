import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { initializeBilling } from './utils/billingUtils';

// Lazy load components
const HomePage = lazy(() => import('./Views/HomePage'));
const LoginPage = lazy(() => import('./Views/LoginPage'));
const LandingPage = lazy(() => import('./Views/LandingPage'));
const StudyPage = lazy(() => import('./Views/StudyPage'));
const ResultPage = lazy(() => import('./components/ResultPage/ResultPage'));
const Settings = lazy(() => import('./Views/Settings'));
const QuizPage = lazy(() => import('./Views/QuizPage'));
const QuizSession = lazy(() => import('./components/QuizSession/QuizSession'));
const HeroPage = lazy(() => import('./components/HeroPage/HeroPage'));
const SubscriptionPage = lazy(() => import('./Views/SubscriptionPage'));
const WelcomePage = lazy(() => import('./Views/WelcomePage'));

// Use existing auth protection
const ProtectedRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();
  
  console.log('ProtectedRoute - Auth State:', { loading, isAuthenticated: !!currentUser });
  
  // While auth is initializing, return null or a loading spinner
  if (loading) {
    console.log('Auth is still loading...');
    return null;
  }
  
  if (!currentUser) {
    console.log('No user found, redirecting to landing');
    return <Navigate to="/" replace />;
  }
  
  console.log('User authenticated, rendering protected content');
  return children;
};

// Create a separate component for routes
const AppRoutes = () => {
  const { currentUser } = useAuth();

  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={
        currentUser ? <Navigate to="/home" replace /> : <LandingPage />
      } />
      <Route path="/login" element={
        currentUser ? <Navigate to="/home" replace /> : <LoginPage />
      } />
      
      {/* Protected routes */}
      <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
      <Route path="/study/:stackId" element={<ProtectedRoute><StudyPage /></ProtectedRoute>} />
      <Route path="/result" element={<ProtectedRoute><ResultPage /></ProtectedRoute>} />
      <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
      <Route path="/heroes" element={<ProtectedRoute><HeroPage /></ProtectedRoute>} />
      <Route path="/subscription" element={<ProtectedRoute><SubscriptionPage /></ProtectedRoute>} />
      
      {/* Quiz routes */}
      <Route path="/quiz" element={<ProtectedRoute><QuizPage /></ProtectedRoute>} />
      <Route path="/quiz-session/:quizId" element={<ProtectedRoute><QuizSession /></ProtectedRoute>} />

      <Route path="/welcome" element={<WelcomePage />} />
    </Routes>
  );
};

function App() {
  useEffect(() => {
    const setupBilling = async () => {
      try {
        await initializeBilling();
        console.log('Billing initialized successfully');
      } catch (error) {
        console.error('Error initializing billing:', error);
      }
    };

    setupBilling();
  }, []);

  return (
    <Router>
      <AuthProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <AppRoutes />
        </Suspense>
      </AuthProvider>
    </Router>
  );
}

export default App;
