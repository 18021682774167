import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

if (typeof window.checkEnv === 'function') {
  window.checkEnv();
}

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: window.env?.REACT_APP_API_KEY,
  authDomain: window.env?.REACT_APP_AUTH_DOMAIN,
  projectId: window.env?.REACT_APP_PROJECT_ID,
  storageBucket: window.env?.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: window.env?.REACT_APP_MESSAGING_SENDER_ID,
  appId: window.env?.REACT_APP_APP_ID,
  measurementId: window.env?.REACT_APP_MEASUREMENT_ID
};

// Check if all required configuration values are present
const requiredKeys = ['apiKey', 'authDomain', 'projectId', 'storageBucket', 'messagingSenderId', 'appId'];
const missingKeys = requiredKeys.filter(key => !firebaseConfig[key]);

if (missingKeys.length > 0) {
  throw new Error('Firebase configuration is incomplete. Check your environment variables or Secret Manager.');
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
