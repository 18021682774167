import { firestore as db, auth } from '../firebase';
import { 
    collection, 
    doc, 
    getDocs, 
    query, 
    where, 
    limit, 
    updateDoc, 
    deleteDoc, 
    runTransaction, 
    getDoc,
    setDoc,
    addDoc,
    writeBatch,
    serverTimestamp,
    orderBy  // Add this import
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const checkUserAuth = () => {
  const user = auth.currentUser;
  if (!user) {
    throw new Error('No authenticated user');
  }
  return user;
};

// Add these constants at the top with other imports
const STACK_LIMITS = {
  FREE: 10,
  PREMIUM: 100
};

export const saveFlashcards = async (flashcards, stackName, isSubscribed) => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    try {
        // Check stack limit for unsubscribed users
        const currentStackCount = await getUserStackCount();
        const stackLimit = isSubscribed ? STACK_LIMITS.PREMIUM : STACK_LIMITS.FREE;
        
        if (currentStackCount >= stackLimit) {
            throw new Error(
                isSubscribed 
                    ? 'You have reached the maximum limit of 100 stacks. Please delete some stacks to create new ones.'
                    : 'Free users can create up to 10 stacks. Please upgrade to premium for more stacks or delete existing ones.'
            );
        }

        const stacksCollection = collection(db, 'users', user.uid, 'stacks');
        const stackRef = doc(stacksCollection);
        const now = new Date();
        // Set expiration to 12 months from creation for all users
        const expiresAt = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000);

        const batch = writeBatch(db);

        // Create stack with expiration date
        batch.set(stackRef, {
            name: stackName,
            createdAt: serverTimestamp(),
            userId: user.uid,
            expiresAt: expiresAt,
            cardCount: flashcards.length
        });

        // Add flashcards with same expiration date
        const flashcardsCollection = collection(stackRef, 'flashcards');
        const savedFlashcards = [];
        flashcards.forEach(flashcard => {
            const flashcardRef = doc(flashcardsCollection);
            batch.set(flashcardRef, {
                ...flashcard,
                createdAt: serverTimestamp(),
                expiresAt: expiresAt
            });
            savedFlashcards.push({
                id: flashcardRef.id,
                ...flashcard
            });
        });

        await batch.commit();

        const savedStack = {
            id: stackRef.id,
            name: stackName,
            createdAt: new Date(),
            userId: user.uid,
            knowledgeScore: 0,
            flashcards: savedFlashcards
        };

        return savedStack;  // Return complete stack object
    } catch (error) {
        throw error;
    }
};

// Add this new function
export const getUserStackCount = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');
  
  const stacksCollection = collection(db, 'users', user.uid, 'stacks');
  const snapshot = await getDocs(stacksCollection);
  return snapshot.docs.length;
};

// Update the updateExpirationTimes function to set 12-month expiration
export const updateExpirationTimes = async (userId, isSubscribed) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const stacksCollection = collection(db, 'users', user.uid, 'stacks');
  const stacks = await getDocs(stacksCollection);

  const batch = writeBatch(db);

  // Set expiration to 12 months from now for all stacks
  const expirationTime = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);

  for (const stackDoc of stacks.docs) {
    const stackRef = doc(stacksCollection, stackDoc.id);
    batch.update(stackRef, { 
      expiresAt: expirationTime,
      updatedAt: serverTimestamp()
    });
  }

  await batch.commit();
};

export const getStacks = async (userId) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const stacksRef = collection(db, 'users', user.uid, 'stacks');
    const q = query(
      stacksRef,
      orderBy('createdAt', 'desc')
    );

    const querySnapshot = await getDocs(q);
    const now = new Date();
    
    // Process each stack to include knowledge score
    const stacks = await Promise.all(querySnapshot.docs.map(async (stackDoc) => {
      // Get flashcards for this stack
      const flashcardsRef = collection(stackDoc.ref, 'flashcards');
      const flashcardsSnapshot = await getDocs(flashcardsRef);
      
      // Calculate box distribution
      const boxDistribution = {
        box1: 0,
        box2: 0,
        box3: 0
      };
      
      flashcardsSnapshot.forEach(doc => {
        const box = doc.data().box || 1;
        boxDistribution[`box${box}`]++;
      });

      const knowledgeScore = calculateKnowledgeScore(boxDistribution);
      
      const stackData = {
        id: stackDoc.id,
        ...stackDoc.data(),
        knowledgeScore
      };

      return stackData;
    }));

    // Filter expired stacks
    return stacks.filter(stack => {
      if (!stack.expiresAt) return true;
      const expirationDate = stack.expiresAt.toDate();
      return now < expirationDate;
    });

  } catch (error) {
    throw error;
  }
};

export const getFlashcardsInStack = async (stackId) => {
  // ... remove entire function
};

export const updateStack = async (stackId, data) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  let stackRef;
  if (stackId) {
    stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    await updateDoc(stackRef, data);
  } else {
    const stacksCollection = collection(db, 'users', user.uid, 'stacks');
    stackRef = await addDoc(stacksCollection, { ...data, createdAt: new Date() });
  }

  const updatedStack = await getDoc(stackRef);
  return { id: updatedStack.id, ...updatedStack.data() };
};

export const deleteStack = async (stackId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
  const flashcardsRef = collection(stackRef, 'flashcards');

  // Delete all flashcards in the stack
  const flashcardDocs = await getDocs(flashcardsRef);
  const batch = writeBatch(db);
  flashcardDocs.forEach((doc) => {
    batch.delete(doc.ref);
  });

  // Delete the stack document
  batch.delete(stackRef);

  // Commit the batch
  await batch.commit();
};

export const updateFlashcard = async (stackId, flashcardId, data) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const flashcardRef = doc(db, 'users', user.uid, 'stacks', stackId, 'flashcards', flashcardId);
  await updateDoc(flashcardRef, data);
};

export const deleteFlashcard = async (stackId, flashcardId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const flashcardRef = doc(db, 'users', user.uid, 'stacks', stackId, 'flashcards', flashcardId);
  await deleteDoc(flashcardRef);
};

export const deleteStackAndFlashcards = async (stackId) => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);

    try {
        await runTransaction(db, async (transaction) => {
            // Get the stack document
            const stackDoc = await transaction.get(stackRef);
            if (!stackDoc.exists()) {
                throw new Error('Stack does not exist');
            }

            // Delete all flashcards in the stack
            const flashcardsCollection = collection(stackRef, 'flashcards');
            const flashcardsSnapshot = await getDocs(flashcardsCollection);
            flashcardsSnapshot.docs.forEach((flashcardDoc) => {
                transaction.delete(flashcardDoc.ref);
            });

            // Delete the stack document
            transaction.delete(stackRef);
        });

    } catch (error) {
        throw error;
    }
};

export const getUserSubscriptionStatus = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);
  
  if (userDoc.exists()) {
    return userDoc.data().isSubscribed || false;
  } else {
    return false;
  }
};

export const getStackWithFirstQuestion = async (stackId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
  const stackDoc = await getDoc(stackRef);
  
  if (!stackDoc.exists()) throw new Error('Stack not found');

  const stackData = stackDoc.data();
  const flashcardsCollection = collection(stackRef, 'flashcards');
  const flashcardsQuery = query(flashcardsCollection);
  const flashcardsSnapshot = await getDocs(flashcardsQuery);
  
  const firstQuestion = flashcardsSnapshot.docs[0]?.data().question || 'No questions yet';
  const flashcardCount = flashcardsSnapshot.size;

  return {
    id: stackDoc.id,
    ...stackData,
    createdAt: stackData.createdAt ? stackData.createdAt.toDate() : null,
    expiresAt: stackData.expiresAt ? stackData.expiresAt.toDate() : null,
    firstQuestion,
    flashcardCount,
  };
};

// Add this new function
const calculateKnowledgeScore = (boxDistribution) => {
  const totalCards = 50;
  const box1Weight = 0;
  const box2Weight = 0.5;
  const box3Weight = 1;
  
  const weightedSum = (
    (boxDistribution.box1 * box1Weight) +
    (boxDistribution.box2 * box2Weight) +
    (boxDistribution.box3 * box3Weight)
  );
  
  return Math.round((weightedSum / totalCards) * 100);
};

// Modify getStackWithFlashcards to include box distribution
export const getStackWithFlashcards = async (stackId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    const stackDoc = await getDoc(stackRef);
    
    if (!stackDoc.exists()) {
      throw new Error('Stack not found');
    }

    const flashcardsRef = collection(stackRef, 'flashcards');
    const flashcardsSnapshot = await getDocs(flashcardsRef);
    
    // Calculate box distribution
    const boxDistribution = {
      box1: 0,
      box2: 0,
      box3: 0
    };
    
    flashcardsSnapshot.forEach(doc => {
      const box = doc.data().box || 1;
      boxDistribution[`box${box}`]++;
    });

    const knowledgeScore = calculateKnowledgeScore(boxDistribution);
    
    return {
      id: stackDoc.id,
      ...stackDoc.data(),
      flashcards: flashcardsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })),
      knowledgeScore
    };
  } catch (error) {
    throw error;
  }
};

export const getUserNickname = async (userId) => {
  try {
    checkUserAuth();
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      return userDoc.data().nickname || 'User';
    } else {
      return 'User';
    }
  } catch (error) {
    return 'User';
  }
};

export const getUserStats = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  
  if (userDoc.exists()) {
    const userData = userDoc.data();
    return {
      points: userData.points || 0,
      level: userData.level || 1,
      levelProgress: userData.levelProgress || 0,
      consecutiveDays: userData.consecutiveDays || 0,
      lastLoginDate: userData.lastLoginDate ? userData.lastLoginDate.toDate() : new Date(),
      consecutiveDaysMultiplier: userData.consecutiveDaysMultiplier || 1,
      consecutiveStacksMultiplier: userData.consecutiveStacksMultiplier || 1,
      stackLengthMultiplier: userData.stackLengthMultiplier || 1,
      completedStacksToday: userData.completedStacksToday || 0,
    };
  } else {
    return {
      points: 0,
      level: 1,
      levelProgress: 0,
      consecutiveDays: 0,
      lastLoginDate: new Date(),
      consecutiveDaysMultiplier: 1,
      consecutiveStacksMultiplier: 1,
      stackLengthMultiplier: 1,
      completedStacksToday: 0,
    };
  }
};

export const updateUserStats = async (userId, stats) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, stats);
};

export const updateStackName = async (stackId, newName) => {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    await updateDoc(stackRef, { name: newName });
};

// Add this function
export const checkStackExpiration = async (stackId) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    const stackDoc = await getDoc(stackRef);
    
    if (!stackDoc.exists()) return false;
    
    const stackData = stackDoc.data();
    if (!stackData.expiresAt) return true;
    
    const now = new Date();
    const expirationDate = stackData.expiresAt.toDate();
    
    return now < expirationDate;
  } catch (error) {
    throw error;
  }
};

// Add this function to handle consecutive stack multiplier updates
export const updateConsecutiveStackMultiplier = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  
  if (!userDoc.exists()) return;
  
  const userData = userDoc.data();
  const now = new Date();
  const lastStackCompletion = userData.lastStackCompletionDate?.toDate();
  const hoursSinceLastCompletion = lastStackCompletion 
    ? (now - lastStackCompletion) / (1000 * 60 * 60) 
    : 24;

  // Reset if more than 1 hour has passed
  if (hoursSinceLastCompletion > 1) {
    await updateDoc(userRef, {
      consecutiveStacksMultiplier: 1,
      completedStacksToday: 1, // Reset to 1 since this is a new completion
      lastStackCompletionDate: now
    });
    return 1; // Return new multiplier
  } else {
    // Increment multiplier if within the hour
    const newCompletedStacksToday = (userData.completedStacksToday || 0) + 1;
    const newMultiplier = Math.min(1 + (newCompletedStacksToday * 0.2), 3); // Cap at 3x

    await updateDoc(userRef, {
      consecutiveStacksMultiplier: newMultiplier,
      completedStacksToday: newCompletedStacksToday,
      lastStackCompletionDate: now
    });
    return newMultiplier;
  }
};

// Add new functions for Leitner system
export const getCardsForStudySession = async (stackId) => {
  const user = checkUserAuth();
  
  try {
    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    const stackDoc = await getDoc(stackRef);
    const sessionCounter = (stackDoc.data()?.sessionCounter || 0) + 1;
    
    const flashcardsRef = collection(stackRef, 'flashcards');
    const snapshot = await getDocs(flashcardsRef);
    
    if (snapshot.empty) {
      return { cards: [], sessionCounter: 0 };
    }

    // Sort all cards into boxes
    const allCards = [];
    let totalCards = 0;
    snapshot.forEach(doc => {
      const data = doc.data();
      allCards.push({
        id: doc.id,
        ...data,
        box: data.box || 1
      });
      totalCards++;
    });

    const box1Cards = allCards.filter(card => card.box === 1);
    const box2Cards = allCards.filter(card => card.box === 2);
    const box3Cards = allCards.filter(card => card.box === 3);

    // Check for mastery first
    if (box3Cards.length === totalCards) {
      return {
        status: 'mastered',
        stats: {
          masteryPercentage: Math.round((box3Cards.length / totalCards) * 100),
          totalCards
        }
      };
    }

    // Determine which boxes should be shown based on session counter
    let availableCards = [
      ...box1Cards,
      ...(sessionCounter % 2 === 1 ? box2Cards : []),
      ...(sessionCounter % 4 === 1 ? box3Cards : [])
    ];

    // Empty session prevention: if no cards available and box2 has cards, include box2
    if (availableCards.length === 0 && box2Cards.length > 0) {
      availableCards = [...box2Cards];
    }

    // Update session counter
    await updateDoc(stackRef, { sessionCounter });

    // Return cards with priority and limit
    const stackData = stackDoc.data();
    
    return {
      cards: availableCards.slice(0, 30),
      sessionCounter,
      stackName: stackData.name,
      stats: {
        totalCards,
        box1Count: box1Cards.length,
        box2Count: box2Cards.length,
        box3Count: box3Cards.length
      }
    };

  } catch (error) {
    throw error;
  }
};

// Add new function to update card box
export const updateCardBox = async (stackId, cardId, wasCorrect, currentBox) => {
  const user = checkUserAuth();
  
  try {
    const cardRef = doc(db, 'users', user.uid, 'stacks', stackId, 'flashcards', cardId);
    
    let newBox = 1; // Default to box 1 for incorrect answers
    
    if (wasCorrect) {
      if (currentBox === 1) newBox = 2;
      else if (currentBox === 2) newBox = 3;
      else newBox = 3; // Stay in box 3 if already there
    }
    
    await updateDoc(cardRef, { box: newBox });
    return newBox;
  } catch (error) {
    throw error;
  }
};

export const resetStackProgress = async (stackId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  try {
    const stackRef = doc(db, 'users', user.uid, 'stacks', stackId);
    const flashcardsRef = collection(stackRef, 'flashcards');
    const snapshot = await getDocs(flashcardsRef);
    
    const batch = writeBatch(db);
    
    // Reset session counter
    batch.update(stackRef, { sessionCounter: 0 });
    
    // Reset all cards to box 1
    snapshot.docs.forEach(doc => {
      batch.update(doc.ref, { box: 1 });
    });
    
    await batch.commit();
    
    return true;
  } catch (error) {
    throw error;
  }
};

// Add this new function
export const getMultipliers = async () => {
  try {
    const functions = getFunctions();
    const getCurrentMultipliers = httpsCallable(functions, 'getCurrentMultipliers');
    const result = await getCurrentMultipliers();
    
    // The result.data will contain the multipliers object
    return result.data.multipliers || {
      consecutiveDays: 1,
      consecutiveStacks: 1,
      correctAnswer: 1
    };
  } catch (error) {
    // Return default multipliers if there's an error
    return {
      consecutiveDays: 1,
      consecutiveStacks: 1,
      correctAnswer: 1
    };
  }
};
