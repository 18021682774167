import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';
import { firestore as db, auth } from '../firebase';

// Test subscription SKU - this should match what you create in Play Console
const SUBSCRIPTION_SKU = 'android.test.purchased';

// Function to detect if running in TWA
export const isTWA = () => {
  // Check for TWA specific properties
  const isTrustedWebActivity = document.referrer.includes('android-app://');
  
  console.log('Platform detection:', {
    isTrustedWebActivity,
    referrer: document.referrer
  });

  return isTrustedWebActivity;
};

// Initialize billing
export const initializeBilling = async () => {
  if (!isTWA()) {
    console.log('Not running as Android TWA, skipping billing initialization');
    return false;
  }
  
  try {
    // The Google Play Billing Library will be initialized by the Android TWA
    return true;
  } catch (error) {
    console.error('Error initializing billing:', error);
    return false;
  }
};

export const purchaseSubscription = async (userId) => {
  if (!isTWA()) {
    console.log('Not running as Android TWA, subscription not available');
    return false;
  }

  try {
    // For testing, we'll simulate a successful purchase
    if (SUBSCRIPTION_SKU === 'android.test.purchased') {
      console.log('Test purchase successful');
      // Generate test purchase data
      const testPurchaseData = {
        productId: SUBSCRIPTION_SKU,
        purchaseToken: 'test_purchase_token_' + Date.now(),
        orderId: 'test_order_' + Date.now()
      };
      console.log('Test purchase data:', testPurchaseData);

      // Call Firebase Function to verify and process the purchase
      const functions = getFunctions();
      const verifyPurchase = httpsCallable(functions, 'verifyGooglePlayPurchase');
      const result = await verifyPurchase(testPurchaseData);

      return result.data.success;
    }

    // For real subscriptions, launch the Google Play subscription flow
    window.location.href = `intent://subscription/${SUBSCRIPTION_SKU}#Intent;scheme=market;package=com.android.vending;end`;
    return true;
  } catch (error) {
    console.error('Error launching subscription:', error);
    return false;
  }
};

export const checkPlayBillingSubscription = async () => {
  if (!isTWA()) {
    console.log('Not running as Android TWA, subscription check not available');
    return false;
  }

  try {
    // We rely on Firestore subscription status for both PayPal and Google Play
    const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
    return userDoc.data()?.isSubscribed || false;
  } catch (error) {
    console.error('Error checking subscription:', error);
    return false;
  }
};

// Clean up function - call this when your app closes
export const closeBillingConnection = async () => {
  if (!isTWA()) return;
  // No cleanup needed for TWA billing
}; 